





















































import { Component, Vue } from 'vue-property-decorator'

@Component
export default class Timer extends Vue {
  animateFrame = 0
  nowTime = 0
  diffTime = 0
  startTime = 0
  leftTime = 180000
  isCountDowning = false
  count = 3
  snackbar = false

  // サウンド
  count10Sound = new Audio(require('@/assets/10.wav'))
  count9Sound = new Audio(require('@/assets/9.wav'))
  count8Sound = new Audio(require('@/assets/8.wav'))
  count7Sound = new Audio(require('@/assets/7.wav'))
  count6Sound = new Audio(require('@/assets/6.wav'))
  count5Sound = new Audio(require('@/assets/5.wav'))
  count4Sound = new Audio(require('@/assets/4.wav'))
  count3Sound = new Audio(require('@/assets/3.wav'))
  count2Sound = new Audio(require('@/assets/2.wav'))
  count1Sound = new Audio(require('@/assets/1.wav'))
  startSound = new Audio(require('@/assets/startSound.wav'))
  finishSound = new Audio(require('@/assets/finishSound.wav'))
  passed30Sound = new Audio(require('@/assets/passed30Sound.wav'))
  passed60Sound = new Audio(require('@/assets/passed60Sound.wav'))
  passed90Sound = new Audio(require('@/assets/passed90Sound.wav'))
  passed120Sound = new Audio(require('@/assets/passed120Sound.wav'))
  passed150Sound = new Audio(require('@/assets/passed150Sound.wav'))
  hasPassed30played = false
  hasPassed60played = false
  hasPassed90played = false
  hasPassed120played = false
  hasPassed150played = false
  hasCount10played = false
  hasCount9played = false
  hasCount8played = false
  hasCount7played = false
  hasCount6played = false
  hasCount5played = false
  hasCount4played = false
  hasCount3played = false
  hasCount2played = false
  hasCount1played = false

  setSubtractStartTime(time: number): void {
    var time2 = typeof time !== 'undefined' ? time : 0
    this.startTime = Math.floor(performance.now() - time2)
  }
  scrollToTricks(group: string): void {
    const e = document.getElementById('group' + group)
    if (e != null) {
      e.scrollIntoView()
    }
    document.body.scrollIntoView()
  }
  startCountDown(group: string): void {
    if (this.$store.state.itemNumA !== 5 || this.$store.state.itemNumB !== 5) {
      this.snackbar = true
      return
    }
    this.scrollToTricks(group)
    setTimeout(() => this.startTimer(group), 3000)
    this.isCountDowning = true
    this.playSound(this.count3Sound)
    setTimeout(() => {
      this.playSound(this.count2Sound)
      this.count = 2
      setTimeout(() => {
        this.playSound(this.count1Sound)
        this.count = 1
        setTimeout(() => {
          this.playSound(this.startSound)
          this.isCountDowning = false
          this.count = 3
        }, 1000)
      }, 1000)
    }, 1000)
  }
  startTimer(group: string): void {
    this.$store.commit('setGroup', group)
    this.setSubtractStartTime(this.diffTime)
    this.loop()
    this.$store.commit('timerOn', true)
  }
  loop(): void {
    if (this.$store.state.timerPaused) {
      this.stopTimer()
    } else {
      this.nowTime = Math.floor(performance.now())
      this.diffTime = this.nowTime - this.startTime
      this.leftTime = 180000 - this.diffTime
      if (!this.hasPassed30played && this.leftTime <= 150000) {
        this.playSound(this.passed30Sound)
        this.hasPassed30played = true
      } else if (!this.hasPassed60played && this.leftTime <= 120000) {
        this.playSound(this.passed60Sound)
        this.hasPassed60played = true
      } else if (!this.hasPassed90played && this.leftTime <= 90000) {
        this.playSound(this.passed90Sound)
        this.hasPassed90played = true
      } else if (!this.hasPassed120played && this.leftTime <= 60000) {
        this.playSound(this.passed120Sound)
        this.hasPassed120played = true
      } else if (!this.hasPassed150played && this.leftTime <= 30000) {
        this.playSound(this.passed150Sound)
        this.hasPassed150played = true
      } else if (!this.hasCount10played && this.leftTime <= 10000) {
        this.playSound(this.count10Sound)
        this.hasCount10played = true
      } else if (!this.hasCount9played && this.leftTime <= 9000) {
        this.playSound(this.count9Sound)
        this.hasCount9played = true
      } else if (!this.hasCount8played && this.leftTime <= 8000) {
        this.playSound(this.count8Sound)
        this.hasCount8played = true
      } else if (!this.hasCount7played && this.leftTime <= 7000) {
        this.playSound(this.count7Sound)
        this.hasCount7played = true
      } else if (!this.hasCount6played && this.leftTime <= 6000) {
        this.playSound(this.count6Sound)
        this.hasCount6played = true
      } else if (!this.hasCount5played && this.leftTime <= 5000) {
        this.playSound(this.count5Sound)
        this.hasCount5played = true
      } else if (!this.hasCount4played && this.leftTime <= 4000) {
        this.playSound(this.count4Sound)
        this.hasCount4played = true
      } else if (!this.hasCount3played && this.leftTime <= 3000) {
        this.playSound(this.count3Sound)
        this.hasCount3played = true
      } else if (!this.hasCount2played && this.leftTime <= 2000) {
        this.playSound(this.count2Sound)
        this.hasCount2played = true
      } else if (!this.hasCount1played && this.leftTime <= 1000) {
        this.playSound(this.count1Sound)
        this.hasCount1played = true
      }
      if (this.leftTime <= 0) {
        this.playSound(this.finishSound)
        this.leftTime = 0
        this.diffTime = 180000
        this.animateFrame = requestAnimationFrame(this.loop)
        this.stopTimer()
        // タイムオーバー時、終了フラグを立てる
        if (this.$store.state.group === 'A') {
          this.$store.commit('isFinishedA', true)
        } else {
          this.$store.commit('isFinishedB', true)
        }
        if (this.$store.state.isFinishedA && this.$store.state.isFinishedB) {
          this.$emit('saveRecord')
        }
      } else {
        this.$store.commit(
          'updateLeftTime',
          this.formatTime + this.formatMilliSeconds
        )
        this.$store.commit('updatePassedTime', this.passedFormatTime)
        this.animateFrame = requestAnimationFrame(this.loop)
      }
    }
  }
  playSound(sound: HTMLAudioElement): void {
    if (this.$store.state.volume > 0) {
      sound.volume = this.$store.state.volume / 100.0
      sound.play()
    }
  }
  stopTimer(): void {
    this.$store.commit('timerOn', false)
    this.$store.commit('timerPaused', true)
    cancelAnimationFrame(this.animateFrame)
  }
  reset(): void {
    this.startTime = 0
    this.nowTime = 0
    this.diffTime = 0
    this.leftTime = 180000
    this.hasPassed30played = false
    this.hasPassed60played = false
    this.hasPassed90played = false
    this.hasPassed120played = false
    this.hasPassed150played = false
    this.hasCount10played = false
    this.hasCount9played = false
    this.hasCount8played = false
    this.hasCount7played = false
    this.hasCount6played = false
    this.hasCount5played = false
    this.hasCount4played = false
    this.hasCount3played = false
    this.hasCount2played = false
    this.hasCount1played = false

    this.stopTimer()
    this.animateFrame = 0
    this.$store.commit(
      'updateLeftTime',
      this.formatTime + this.formatMilliSeconds
    )
    this.$store.commit('timerOn', false)
    this.$store.commit('timerPaused', false)
  }
  zeroPad(value: number, num = 2): string {
    return value.toString().padStart(num, '0')
  }
  get formatTime(): string {
    return `${this.minutes}:${this.zeroPad(this.seconds)}`
  }
  get formatMilliSeconds(): string {
    return `.${this.zeroPad(this.milliSeconds, 3)}`
  }
  get minutes(): number {
    return Math.floor(this.leftTime / 1000 / 60) % 60
  }
  get seconds(): number {
    return Math.floor(this.leftTime / 1000) % 60
  }
  get milliSeconds(): number {
    return Math.floor(this.leftTime % 1000)
  }
  get passedFormatTime(): string {
    return `${this.passedMinutes}:${this.zeroPad(
      this.passedSeconds
    )}.${this.zeroPad(this.passedMilliSeconds, 3)}`
  }
  get passedMinutes(): number {
    return Math.floor(this.diffTime / 1000 / 60) % 60
  }
  get passedSeconds(): number {
    return Math.floor(this.diffTime / 1000) % 60
  }
  get passedMilliSeconds(): number {
    return Math.floor(this.diffTime % 1000)
  }
}
