import Vue from 'vue'
import App from './App.vue'
import store from './store'
import vuetify from './plugins/vuetify'
import VModal from 'vue-js-modal'
import VueGtag from 'vue-gtag'
import ToggleButton from 'vue-js-toggle-button'
import '@fortawesome/fontawesome-free/js/fontawesome'
import '@fortawesome/fontawesome-free/js/brands'

Vue.config.productionTip = false

Vue.use(VModal)
Vue.use(VueGtag, {
  config: { id: 'G-PSM7J5LMGY' },
})
Vue.use(ToggleButton)

new Vue({
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app')
