


























































































































































































import draggable from 'vuedraggable'
import { Trick, Record, TrickRecord } from '@/data'
import { Component, Vue } from 'vue-property-decorator'

@Component({ components: { draggable } })
export default class TrickList extends Vue {
  options = {
    group: 'myGroup',
    animation: 200,
  }
  itemsA: Trick[] = []
  itemsB: Trick[] = []
  itemsC: Trick[] = this.$store.state.allTrick
  maxScore: { [group: string]: number } = { A: 0, B: 0 }
  score: { [group: string]: number } = { A: 0, B: 0 }
  listName = ''
  listNames: string[] = []
  selectedListName = ''
  cleared: { [group: string]: boolean[] } = {
    A: [false, false, false, false, false],
    B: [false, false, false, false, false],
  }
  year: number = this.$store.state.year
  yearKey: string = this.year == 2021 ? '' : this.year.toString()

  calc(): void {
    this.maxScore['A'] = 0
    this.maxScore['B'] = 0
    this.itemsA.forEach((item) => {
      this.maxScore['A'] += item['score']
    })
    this.itemsB.forEach((item) => {
      this.maxScore['B'] += item['score']
    })
    this.$store.commit('setItemsA', this.itemsA)
    this.$store.commit('setItemsB', this.itemsB)
    this.$store.commit('setItemNumA', this.itemsA.length)
    this.$store.commit('setItemNumB', this.itemsB.length)
  }
  record(group: string, index: number, score: number): void {
    if (this.$store.state.timerOn) {
      if (this.$store.state.group === group) {
        if (!this.cleared[group][index]) {
          this.cleared[group][index] = true
          this.score[group] += score
          if (group === 'A') {
            this.itemsA[index].leftTime = this.$store.state.leftTime
            this.itemsA[index].passedTime = this.$store.state.passedTime
          } else {
            this.itemsB[index].leftTime = this.$store.state.leftTime
            this.itemsB[index].passedTime = this.$store.state.passedTime
          }
        } else {
          this.cleared[group][index] = false
          this.score[group] -= score
          if (group === 'A') {
            this.itemsA[index].leftTime = ''
            this.itemsA[index].passedTime = ''
          } else {
            this.itemsB[index].leftTime = ''
            this.itemsB[index].passedTime = ''
          }
        }
      }
      this.judge()
    }
  }
  judge(): void {
    if (
      this.$store.state.group === 'A' &&
      this.itemsA.filter((item) => item.leftTime != '').length >= 5 &&
      this.$store.state.timerOn
    ) {
      this.$store.commit('isFinishedA', true)
      this.pauseTimer()
    } else if (
      this.$store.state.group === 'B' &&
      this.itemsB.filter((item) => item.leftTime != '').length >= 5 &&
      this.$store.state.timerOn
    ) {
      this.$store.commit('isFinishedB', true)
      this.pauseTimer()
    }
    if (
      this.$store.state.isFinishedA &&
      this.$store.state.isFinishedB &&
      this.$store.state.isSaveRecordEnabled
    ) {
      this.saveRecord()
    }
  }
  pauseTimer(): void {
    this.$store.commit('timerPaused', true)
    this.$store.commit('timerOn', false)
  }
  saveRecord(): void {
    const dateTime = new Date().getTime()
    const trickRecordsA: TrickRecord[] = []
    const trickRecordsB: TrickRecord[] = []
    this.itemsA.forEach((item) => {
      const trickRecord: TrickRecord = {
        trick: item.name,
        leftTime: item.leftTime,
        passedTime: item.passedTime,
      }
      trickRecordsA.push(trickRecord)
    })
    this.itemsB.forEach((item) => {
      const trickRecord: TrickRecord = {
        trick: item.name,
        leftTime: item.leftTime,
        passedTime: item.passedTime,
      }
      trickRecordsB.push(trickRecord)
    })

    const record: Record = {
      dateTime: dateTime,
      scoreA: this.score['A'],
      scoreB: this.score['B'],
      groupA: trickRecordsA,
      groupB: trickRecordsB,
    }
    const recordJson = JSON.stringify(record)
    console.log(recordJson)
    localStorage.setItem(this.yearKey + dateTime.toString(), recordJson)
    this.renderRecordChart()

    // 結果を表示
    if (this.$store.state.isShowResultEnabled) {
      this.$store.commit('setModalDateTime', record.dateTime)
      this.$store.commit('setModalScoreA', record.scoreA)
      this.$store.commit('setModalScoreB', record.scoreB)
      this.$store.commit('setModalTricksA', record.groupA)
      this.$store.commit('setModalTricksB', record.groupB)
      this.$emit('show')
    }
  }
  saveTricks(): void {
    console.log(this.listName)
    if (this.listName != null && this.listName != '') {
      // A, B, Cのidを保存
      const idsA = this.itemsA.map((x: Trick) => x.id).join(',')
      const idsB = this.itemsB.map((x: Trick) => x.id).join(',')
      localStorage.setItem(this.yearKey + 'idsA' + this.listName, idsA)
      localStorage.setItem(this.yearKey + 'idsB' + this.listName, idsB)
      this.listNames.push(this.listName)
      this.saveListNames()
      this.hideSave()
    }
  }
  saveListNames(): void {
    localStorage.setItem(this.yearKey + 'listNames', this.listNames.join(','))
  }
  loadListNames(): void {
    const tmp = localStorage.getItem(this.yearKey + 'listNames')
    if (tmp != null) {
      this.listNames = tmp.split(',')
    }
  }
  loadTricks(): void {
    const savedIdsA = localStorage.getItem(
      this.yearKey + 'idsA' + this.selectedListName
    )
    const savedIdsB = localStorage.getItem(
      this.yearKey + 'idsB' + this.selectedListName
    )
    const year = this.year
    const all = this.$store.state.allTrick.filter(
      (trick: any) => trick.year == year
    )
    var idsA: string[] = []
    var idsB: string[] = []
    if (savedIdsA != null) {
      idsA = savedIdsA.split(',')
      this.itemsA = all.filter((item: Trick) =>
        idsA.includes(item.id.toString())
      )
    }
    if (savedIdsB != null) {
      idsB = savedIdsB.split(',')
      this.itemsB = all.filter((item: Trick) =>
        idsB.includes(item.id.toString())
      )
    }
    this.itemsC = all.filter(
      (item: Trick) =>
        !idsA.includes(item.id.toString()) && !idsB.includes(item.id.toString())
    )
    this.calc()
    this.resetCleared()
  }
  saveDefaultTricksName(): void {
    if (this.selectedListName != '') {
      localStorage.setItem(
        this.yearKey + 'defaultTricksName',
        this.selectedListName
      )
    }
  }
  loadDefaultTricksName(): void {
    const defaultTricksName = localStorage.getItem(
      this.yearKey + 'defaultTricksName'
    )
    if (defaultTricksName != null) {
      this.selectedListName = defaultTricksName
    }
  }
  resetCleared(): void {
    this.itemsA.forEach((item) => {
      item.leftTime = ''
      item.passedTime = ''
    })
    this.itemsB.forEach((item) => {
      item.leftTime = ''
      item.passedTime = ''
    })
    this.cleared['A'] = [false, false, false, false, false]
    this.cleared['B'] = [false, false, false, false, false]
    this.score['A'] = 0
    this.score['B'] = 0
    this.$store.commit('isFinishedA', false)
    this.$store.commit('isFinishedB', false)
  }
  showSave(): void {
    this.$modal.show('modal-save')
  }
  hideSave(): void {
    this.$modal.hide('modal-save')
  }
  renderRecordChart(): void {
    this.$emit('renderRecordChart')
  }
  mounted(): void {
    this.loadDefaultTricksName()
    this.loadTricks()
    this.resetCleared()
    this.loadListNames()
  }
}
