
import { Record, TrickRecord } from '@/data'
import Chart, { ChartTooltipItem } from 'chart.js'
import { Bar } from 'vue-chartjs'
import { Component, Mixins } from 'vue-property-decorator'

@Component({ mixins: [Bar] })
export default class RecordChart extends Mixins(Bar) {
  chartData: Chart.ChartData = {
    labels: [],
    datasets: [],
  }
  options = {
    responsive: true,
    onClick: this.handle,
    scales: {
      xAxes: [
        {
          stacked: true,
          scaleLabel: {
            display: true,
            labelString: '日時',
          },
        },
      ],
      yAxes: [
        {
          id: 'score',
          type: 'linear',
          position: 'left',
          stacked: true,
          scaleLabel: {
            display: true,
            labelString: 'スコア',
          },
          ticks: {
            beginAtZero: true,
          },
        },
        {
          id: 'time',
          type: 'linear',
          position: 'right',
          scaleLabel: {
            display: true,
            labelString: '残り時間',
          },
          ticks: {
            beginAtZero: true,
            max: 180,
          },
        },
      ],
    },
    tooltips: {},
  }
  records: Record[] = []
  year: number = this.$store.state.year
  yearKey: string = this.year == 2021 ? '' : this.year.toString()

  loadRecords(): void {
    this.records = []
    var keys: string[] = []
    for (const key in localStorage) {
      keys.push(key)
    }
    keys.sort()
    for (const key of keys) {
      const reg = new RegExp('^' + this.yearKey + '\\d{13}$')
      if (key.search(reg) >= 0) {
        const json = localStorage.getItem(key)
        if (json != null) {
          const record: Record = JSON.parse(json)
          this.records.push(record)
        }
      }
    }
  }
  handle(_event: MouseEvent, items: any): void {
    const item = items[0]
    const idx = item._index
    const tricksA = this.records[idx].groupA
    const tricksB = this.records[idx].groupB
    this.$store.commit('setModalDateTime', this.records[idx].dateTime)
    this.$store.commit('setModalScoreA', this.records[idx].scoreA)
    this.$store.commit('setModalScoreB', this.records[idx].scoreB)
    this.$store.commit('setModalTricksA', tricksA)
    this.$store.commit('setModalTricksB', tricksB)
    this.$emit('show')
  }
  renderRecordChart(): void {
    this.loadRecords()
    this.chartData.labels = this.records.map((x) => {
      const dateTime = new Date(x.dateTime)
      return dateTime.toLocaleDateString() + ' ' + dateTime.toLocaleTimeString()
    })
    this.chartData.datasets = [
      {
        yAxisID: 'score',
        type: 'bar',
        label: 'スコア1',
        data: this.records.map((x) => x.scoreA),
        borderWidth: 1,
        backgroundColor: '#ed2d2d88',
        borderColor: '#ce1720',
      },
      {
        yAxisID: 'score',
        type: 'bar',
        label: 'スコア2',
        data: this.records.map((x) => x.scoreB),
        borderWidth: 1,
        backgroundColor: '#552ded88',
        borderColor: '#3020de',
      },
      {
        yAxisID: 'time',
        type: 'line',
        fill: false,
        pointStyle: 'circle',
        lineTension: 0,
        label: '残り時間1',
        data: this.records.map((x) => this.minLeftTime(x.groupA)),
        borderWidth: 1,
        borderColor: '#ed2d8d',
      },
      {
        yAxisID: 'time',
        type: 'line',
        fill: false,
        pointStyle: 'triangle',
        lineTension: 0,
        label: '残り時間2',
        data: this.records.map((x) => this.minLeftTime(x.groupB)),
        borderWidth: 1,
        borderColor: '#2d67ed',
      },
    ]
    this.options.tooltips = {
      mode: 'nearest',
      callbacks: {
        label: (tooltipItem: ChartTooltipItem) => {
          if (tooltipItem.index !== undefined) {
            var trickRecords: TrickRecord[]
            if (tooltipItem.datasetIndex === 0) {
              trickRecords = this.records[tooltipItem.index].groupA
            } else if (tooltipItem.datasetIndex === 1) {
              trickRecords = this.records[tooltipItem.index].groupB
            } else if (tooltipItem.datasetIndex === 2) {
              return `1：残り${
                Math.round(
                  this.minLeftTime(this.records[tooltipItem.index].groupA) *
                    1000
                ) / 1000
              }秒`
            } else {
              return `2：残り${
                Math.round(
                  this.minLeftTime(this.records[tooltipItem.index].groupB) *
                    1000
                ) / 1000
              }秒`
            }
            const tricks = trickRecords.map(
              (record) => record.trick.slice(0, 8) + '...'
            )
            return `${tricks.join(', ')}`
          }
        },
      },
      bodyFontSize: 10,
    }
    this.renderChart(this.chartData, this.options)
  }
  minLeftTime(records: TrickRecord[]): number {
    const times = records.map((x) => x.leftTime)
    times.sort()
    return times[0] === ''
      ? 0
      : Number(times[0].split(':')[0]) * 60 + Number(times[0].split(':')[1])
  }
  mounted(): void {
    this.renderRecordChart()
  }
}
