













































































































































































































import TrickList from './components/TrickList.vue'
import Timer from './components/Timer.vue'
import RecordChart from './components/RecordChart.vue'
import Usage from './components/Usage.vue'
import Disclaimer from './components/Disclaimer.vue'
import Settings from './components/Settings.vue'
import { Component, Vue } from 'vue-property-decorator'
import { saveAs } from 'file-saver'
import { Trick } from './data'

@Component({
  components: {
    TrickList,
    Timer,
    RecordChart,
    Usage,
    Disclaimer,
    Settings,
  },
})
export default class App extends Vue {
  modalWidth = this.widthFromScreenSize()
  drawer = false
  snackbar = false
  year = this.$store.state.year
  yearKey: string = this.year == 2021 ? '' : this.year.toString()
  createSheetUrl = 'https://johnquestion.pythonanywhere.com'

  show(): void {
    this.$modal.show('modal-test')
  }
  hide(): void {
    this.$modal.hide('modal-test')
  }
  showUsage(): void {
    this.$modal.show('modal-usage')
  }
  hideUsage(): void {
    this.$modal.hide('modal-usage')
  }
  showDisclaimer(): void {
    this.$modal.show('modal-disclaimer')
  }
  hideDisclaimer(): void {
    this.$modal.hide('modal-disclaimer')
  }
  showSettings(): void {
    this.$modal.show('modal-settings')
  }
  hideSettings(): void {
    this.$modal.hide('modal-settings')
  }
  createScoreSheet(): void {
    const itemsA = this.$store.state.itemsA
    const itemsB = this.$store.state.itemsB
    const tList = itemsA.concat(itemsB)
    console.log(tList)
    window.open(
      `${this.createSheetUrl}?year=${this.$store.state.year}&tList=${tList
        .map((e: Trick) => {
          return e.name.split('：')[0]
        })
        .join(',')}`,
      '_blank'
    )
  }
  $refs!: {
    recordChart: RecordChart
    saveRecord: TrickList
  }
  deleteRecord(): void {
    localStorage.removeItem(
      this.yearKey + this.$store.state.modalDateTime.toString()
    )
    this.renderRecordChart()
    this.hide()
  }
  renderRecordChart(): void {
    this.$refs.recordChart.renderRecordChart()
  }
  saveRecord(): void {
    this.$refs.saveRecord.saveRecord()
  }

  widthFromScreenSize(): string {
    return screen.width < 800 ? '90%' : '50%'
  }
  download(): void {
    const blob = new Blob([JSON.stringify(localStorage)], {
      type: 'application/json',
    })
    saveAs(blob, 'local_storage.json')
  }
  clickInput(): void {
    const input = document.getElementById('input')
    if (input != null) {
      input.click()
    }
  }
  upload(event: Event): void {
    if (event.target != null) {
      const target = event.target as HTMLInputElement
      const files = target.files
      if (files != null) {
        const file = files[0]
        if (!this.checkFile(file)) {
          this.snackbar = true
          return
        }
        const reader = new FileReader()
        reader.readAsText(file)
        reader.addEventListener('load', () => {
          const text = reader.result as string
          const obj = JSON.parse(text)
          Object.keys(obj).forEach(function (key) {
            localStorage.setItem(key, obj[key])
          })
          location.reload()
        })
      }
    }
  }
  checkFile(file: File): boolean {
    if (!file || file.type !== 'application/json') {
      return false
    }
    const SIZE_LIMIT = 5000000 // 5MB
    if (file.size > SIZE_LIMIT) {
      return false
    }
    return true
  }
  loadSettings(): void {
    if (localStorage.getItem('isSaveRecordEnabled') === 'false') {
      this.$store.commit('isSaveRecordEnabled', false)
    }
    if (localStorage.getItem('isShowResultEnabled') === 'false') {
      this.$store.commit('isShowResultEnabled', false)
    }
    const volume = localStorage.getItem('volume')
    if (volume !== null) {
      this.$store.commit('setVolume', volume)
    }
    const lsYear = localStorage.getItem('year')
    if (lsYear) {
      console.log('year loaded')
      this.year = parseInt(lsYear)
      this.$store.commit('setYear', this.year)
    }
  }
  twitterShare(): void {
    //シェアする画面を設定
    const score = this.$store.state.modalScoreA + this.$store.state.modalScoreB
    var message = 'KWC Timerで' + score + '点を獲得しました！%0a'
    var cleared = []
    var failed = []
    for (let i of this.$store.state.modalTricksA) {
      if (i.leftTime) {
        cleared.push(i.trick.split('：')[0])
      } else {
        failed.push(i.trick.split('：')[0])
      }
    }
    for (let i of this.$store.state.modalTricksB) {
      if (i.leftTime) {
        cleared.push(i.trick.split('：')[0])
      } else {
        failed.push(i.trick.split('：')[0])
      }
    }
    message +=
      '【成功したトリック】%0a' +
      (cleared.length == 0 ? 'なし' : cleared.join('、')) +
      '%0a'
    message +=
      '【成功できなかったトリック】%0a' +
      (failed.length == 0 ? 'なし' : failed.join('、')) +
      '%0a'
    const shareURL =
      'https://twitter.com/intent/tweet?text=' +
      message +
      '&url=https://kwc-timer.com' +
      '&hashtags=けん玉,KWCTimer'
    //シェア用の画面を別タブで開く
    window.open(shareURL, '_blank')
  }
  created(): void {
    window.addEventListener('orientationchange', () => {
      console.log(screen.width)
      this.modalWidth = this.widthFromScreenSize()
    })
    this.loadSettings()
  }
}
