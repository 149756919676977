import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    leftTime: '3:00:000',
    passedTime: '0:00:000',
    group: 'A',
    itemsA: [],
    itemsB: [],
    itemNumA: 0,
    itemNumB: 0,
    timerOn: false,
    timerPaused: false,
    isFinishedA: false,
    isFinishedB: false,
    modalDateTime: '',
    modalScoreA: 0,
    modalScoreB: 0,
    modalTricksA: [],
    modalTricksB: [],
    isSaveRecordEnabled: true,
    isShowResultEnabled: true,
    volume: 100,
    year: 2023,
    allTrick: [
      { id: 11, name: '1-1：スパイク', score: 1, year: 2021 },
      { id: 12, name: '1-2：飛行機', score: 1, year: 2021 },
      { id: 13, name: '1-3：手のせ大皿～けん', score: 1, year: 2021 },
      { id: 14, name: '1-4：空中ブランコ', score: 1, year: 2021 },
      { id: 15, name: '1-5：たこやき', score: 1, year: 2021 },
      { id: 16, name: '1-6：野球', score: 1, year: 2021 },
      { id: 17, name: '1-7：ろうそく', score: 1, year: 2021 },
      { id: 18, name: '1-8：手のせ大皿けんけんぱ2回', score: 1, year: 2021 },
      { id: 19, name: '1-9：手拍子つるし持ち', score: 1, year: 2021 },
      { id: 20, name: '1-10：もしかめ11回', score: 1, year: 2021 },
      { id: 21, name: '2-1：もしかめ10回以上～けん', score: 2, year: 2021 },
      { id: 22, name: '2-2：はねけん', score: 2, year: 2021 },
      { id: 23, name: '2-3：日本一周', score: 2, year: 2021 },
      { id: 24, name: '2-4：地球まわし', score: 2, year: 2021 },
      { id: 25, name: '2-5：つるしとめけん', score: 2, year: 2021 },
      { id: 26, name: '2-6：たけとんぼ', score: 2, year: 2021 },
      { id: 27, name: '2-7：うぐいす～けん', score: 2, year: 2021 },
      { id: 28, name: '2-8：灯台～さかおとし', score: 2, year: 2021 },
      { id: 29, name: '2-9：秘竜のぼりけん', score: 2, year: 2021 },
      { id: 30, name: '2-10：ダウンスパイク', score: 2, year: 2021 },
      { id: 31, name: '3-1：フラミンゴスパイク', score: 3, year: 2021 },
      { id: 32, name: '3-2：1回転飛行機', score: 3, year: 2021 },
      { id: 33, name: '3-3：宇宙一周', score: 3, year: 2021 },
      { id: 34, name: '3-4：ワールウィンド', score: 3, year: 2021 },
      { id: 35, name: '3-5：ジャグル大皿～けん', score: 3, year: 2021 },
      { id: 36, name: '3-6：1回転灯台～けん', score: 3, year: 2021 },
      {
        id: 37,
        name: '3-7：大皿～けんフリップ大皿～けん',
        score: 3,
        year: 2021,
      },
      { id: 38, name: '3-8：月面着陸～1回転さかおとし', score: 3, year: 2021 },
      { id: 39, name: '3-9：ターンテーブル～けん', score: 3, year: 2021 },
      { id: 40, name: '3-10：レジェンド', score: 3, year: 2021 },
      { id: 41, name: '4-1：ジャグルとめけん', score: 4, year: 2021 },
      { id: 42, name: '4-2：ブリッジ～1回転飛行機', score: 4, year: 2021 },
      { id: 43, name: '4-3：USA一周', score: 4, year: 2021 },
      { id: 44, name: '4-4：風車けん～風車地球まわし', score: 4, year: 2021 },
      { id: 45, name: '4-5：つるしけんフリップけん', score: 4, year: 2021 },
      { id: 46, name: '4-6：たけうま～1回転さかおとし', score: 4, year: 2021 },
      { id: 47, name: '4-7：中皿極意～回転けん', score: 4, year: 2021 },
      {
        id: 48,
        name: '4-8：うら月面～月面～1回転さかおとし',
        score: 4,
        year: 2021,
      },
      {
        id: 49,
        name: '4-9：大皿～ショービット大皿～けん',
        score: 4,
        year: 2021,
      },
      { id: 50, name: '4-10：うぐいす～ダウンスパイク', score: 4, year: 2021 },
      { id: 51, name: '5-1：稲妻おとしスワップけん', score: 5, year: 2021 },
      { id: 52, name: '5-2：2回転飛行機～2回転はねけん', score: 5, year: 2021 },
      { id: 53, name: '5-3：デンマーク一周', score: 5, year: 2021 },
      { id: 54, name: '5-4：ダブルワールウィンド', score: 5, year: 2021 },
      { id: 55, name: '5-5：タップジャグルとめけん', score: 5, year: 2021 },
      {
        id: 56,
        name: '5-6：２回転灯台とんぼ返り～うら２回転灯台とんぼ返り～さかおとし',
        score: 5,
        year: 2021,
      },
      {
        id: 57,
        name: '5-7：うぐいすこっくり谷渡り～回転けん',
        score: 5,
        year: 2021,
      },
      {
        id: 58,
        name: '5-8：うら月面～1回転クッションうら月面～さかおとし',
        score: 5,
        year: 2021,
      },
      { id: 59, name: '5-9：スネークバイト～飛行機', score: 5, year: 2021 },
      { id: 60, name: '5-10：地獄ぐるまファストハンド', score: 5, year: 2021 },
      {
        id: 61,
        name: '6-1：スイーツスペシャルうぐいす～回転けん',
        score: 6,
        year: 2021,
      },
      {
        id: 62,
        name: '6-2：1回転飛行機～1回転灯立～1回転さかおとし～1.5回転とめけん',
        score: 6,
        year: 2021,
      },
      { id: 63, name: '6-3：さかおとし一周', score: 6, year: 2021 },
      {
        id: 64,
        name: '6-4：アンダーバード～ジャグルアンダーバード～さかおとし',
        score: 6,
        year: 2021,
      },
      { id: 65, name: '6-5：風車ジャグル灯台～けん', score: 6, year: 2021 },
      {
        id: 66,
        name: '6-6：稲妻おとし1回転灯台～ダウンスパイク',
        score: 6,
        year: 2021,
      },
      {
        id: 67,
        name: '6-7：前ふりバックワードうら月面～バックフリップうら月面～けん',
        score: 6,
        year: 2021,
      },
      {
        id: 68,
        name: '6-8：うら月面～太ももキャッチ～１回転うら月面～1回転けん',
        score: 6,
        year: 2021,
      },
      { id: 69, name: '6-9：バイーンはねけん', score: 6, year: 2021 },
      {
        id: 70,
        name: '6-10：1回転灯台インスタジャグルダウンスパイク',
        score: 6,
        year: 2021,
      },
      { id: 71, name: '7-1：マテオチャンス', score: 7, year: 2021 },
      {
        id: 72,
        name: '7-2：1回転飛行機～1.5スワップ2回転トス灯台～さかおとし',
        score: 7,
        year: 2021,
      },
      { id: 73, name: '7-3：ワールウィンド1-2-3', score: 7, year: 2021 },
      {
        id: 74,
        name: '7-4：飛行機～スワップけんフリップ1.5回転トスイン',
        score: 7,
        year: 2021,
      },
      {
        id: 75,
        name: '7-5：半円月はやてストリングピンチスワップけん',
        score: 7,
        year: 2021,
      },
      {
        id: 76,
        name:
          '7-6：すくいけんファストハンド～ゴーストジャグルすくいけんファストハンド',
        score: 7,
        year: 2021,
      },
      {
        id: 77,
        name: '7-7：アンダーバード～風車アンダーバード～風車ダウンスパイク',
        score: 7,
        year: 2021,
      },
      { id: 78, name: '7-8：極意谷渡り～回転けん', score: 7, year: 2021 },
      {
        id: 79,
        name: '7-9：うら月面～フラットスピン360うら月面～さかおとし',
        score: 7,
        year: 2021,
      },
      {
        id: 80,
        name: '7-10：スクランブル玉円月バックーワードうら月面～ダウンスパイク',
        score: 7,
        year: 2021,
      },
      {
        id: 81,
        name: '8-1：2タップジャグル2タップジャグルけん',
        score: 8,
        year: 2021,
      },
      {
        id: 82,
        name: '8-2：タップジャグルけんフリップうら1回転トスイン',
        score: 8,
        year: 2021,
      },
      { id: 83, name: '8-3：灯台とんぼ返り1-2-3～けん', score: 8, year: 2021 },
      {
        id: 84,
        name:
          '8-4：うら月面～うらフリップタップ1回転うら月面～1回転クッションさかおとし',
        score: 8,
        year: 2021,
      },
      {
        id: 85,
        name: '8-5：2回転けんフリップ2回転トス灯台インスタ2回転スワップけん',
        score: 8,
        year: 2021,
      },
      {
        id: 86,
        name: '8-6：たけうま～スワップ1.5トスたけうま～1回転さかおとし',
        score: 8,
        year: 2021,
      },
      {
        id: 87,
        name: '8-7：小田原提灯～フォワードすくいけんファストハンド',
        score: 8,
        year: 2021,
      },
      {
        id: 88,
        name:
          '8-8：1回転まさかり～まさかりターンテーブル～すくいけんファストハンド',
        score: 8,
        year: 2021,
      },
      {
        id: 89,
        name:
          '8-9：2回転灯台～マウストス2回転灯台～マウススワップダウンスパイク',
        score: 8,
        year: 2021,
      },
      {
        id: 90,
        name: '8-10：灯台～クラウドバウンス灯台～さか落とし',
        score: 8,
        year: 2021,
      },
      { id: 91, name: '9-1：10ジャグルとめけん', score: 9, year: 2021 },
      {
        id: 92,
        name:
          '9-2：飛行機～2回転ダーディーフィンガーフリップ風車1.5回転トスイン',
        score: 9,
        year: 2021,
      },
      {
        id: 93,
        name: '9-3：飛行機～スワップゴーストさかおとし1-2-3',
        score: 9,
        year: 2021,
      },
      {
        id: 94,
        name: '9-4：2回転風車中皿極意～フォーワードタップ2回転さかおとし',
        score: 9,
        year: 2021,
      },
      {
        id: 95,
        name: '9-5：2タップジャグルリーンハウス～1回転さかおとし',
        score: 9,
        year: 2021,
      },
      {
        id: 96,
        name: '9-6：たけうまころがし～うら1回転さかおとし',
        score: 9,
        year: 2021,
      },
      {
        id: 97,
        name: '9-7：大皿極意～ジャグル大皿極意～回転けん',
        score: 9,
        year: 2021,
      },
      {
        id: 98,
        name: '9-8：うら月面～ヒッピーフリップうら月面～さかおとし',
        score: 9,
        year: 2021,
      },
      {
        id: 99,
        name: '9-9：うぐいす～ボディオービットうぐいす～回転けん',
        score: 9,
        year: 2021,
      },
      {
        id: 100,
        name: '9-10：ペンギンアンダーバード一周～回転けん',
        score: 9,
        year: 2021,
      },
      {
        id: 101,
        name:
          '10-1：フォワードタップタップジャグル2タップジャグル2回転けんフリップけん',
        score: 10,
        year: 2021,
      },
      {
        id: 102,
        name: '10-2：タップ1.5回転ジャグル x 3->ジャグルけん',
        score: 10,
        year: 2021,
      },
      {
        id: 103,
        name: '10-3：まさかりとんぼ返り1-2-3～さかおとし',
        score: 10,
        year: 2021,
      },
      {
        id: 104,
        name:
          '10-4：タップ灯台インスタ3回転フリップスワップタップ灯台～3回転スワップけん',
        score: 10,
        year: 2021,
      },
      {
        id: 105,
        name:
          '10-5：たけうま～1.5回転タップうら1.5回転バックワードたけうま～1.5回転けん',
        score: 10,
        year: 2021,
      },
      {
        id: 106,
        name: '10-6：3回転灯台インスタ3回転フリップスワップ3回転トスイン',
        score: 10,
        year: 2021,
      },
      {
        id: 107,
        name: '10-7：4タップジャグル中皿極意～回転けん',
        score: 10,
        year: 2021,
      },
      {
        id: 108,
        name:
          '10-8：うら月面～3回転けんフリップジャグル x 3->1回転トスうら月面～さかおとし',
        score: 10,
        year: 2021,
      },
      {
        id: 109,
        name:
          '10-9：うら1回転灯台～リフティングうら回転 x 3 -> 灯台～うらさかおとし',
        score: 10,
        year: 2021,
      },
      {
        id: 110,
        name:
          '10-10：うら月面～1回転タップゴーストジャグルタップ1回転うら月面～1.5回転スワップペンギンけん',
        score: 10,
        year: 2021,
      },
      /* 2022年 */
      { id: 202211, name: '1-1：たこやき', score: 1, year: 2022 },
      { id: 202212, name: '1-2：飛行機', score: 1, year: 2022 },
      { id: 202213, name: '1-3：手のせ大皿～けん', score: 1, year: 2022 },
      { id: 202214, name: '1-4：空中ブランコ', score: 1, year: 2022 },
      { id: 202215, name: '1-5：スパイク', score: 1, year: 2022 },
      { id: 202216, name: '1-6：野球', score: 1, year: 2022 },
      { id: 202217, name: '1-7：ろうそく', score: 1, year: 2022 },
      { id: 202218, name: '1-8：大皿', score: 1, year: 2022 },
      { id: 202219, name: '1-9：手拍子つるし持ち', score: 1, year: 2022 },
      { id: 202220, name: '1-10：もしかめ11回', score: 1, year: 2022 },
      { id: 202221, name: '2-1：もしかめ10回以上～けん', score: 2, year: 2022 },
      { id: 202222, name: '2-2：はねけん', score: 2, year: 2022 },
      { id: 202223, name: '2-3：世界一周', score: 2, year: 2022 },
      { id: 202224, name: '2-4：地球まわし', score: 2, year: 2022 },
      { id: 202225, name: '2-5：つるしとめけん', score: 2, year: 2022 },
      { id: 202226, name: '2-6：灯台～さかおとし', score: 2, year: 2022 },
      { id: 202227, name: '2-7：うぐいす～けん', score: 2, year: 2022 },
      { id: 202228, name: '2-8：すくいけん', score: 2, year: 2022 },
      { id: 202229, name: '2-9：秘竜のぼりけん', score: 2, year: 2022 },
      { id: 202230, name: '2-10：ダウンスパイク', score: 2, year: 2022 },
      { id: 202231, name: '3-1：ろうそく～けん', score: 3, year: 2022 },
      { id: 202232, name: '3-2：１回転飛行機', score: 3, year: 2022 },
      { id: 202233, name: '3-3：宇宙遊泳', score: 3, year: 2022 },
      { id: 202234, name: '3-4：ワールウィンド', score: 3, year: 2022 },
      { id: 202235, name: '3-5：ジャグル大皿～けん', score: 3, year: 2022 },
      { id: 202236, name: '3-6：１回転灯台〜けん', score: 3, year: 2022 },
      {
        id: 202237,
        name: '3-7：大皿～けんフリップ大皿～けん',
        score: 3,
        year: 2022,
      },
      {
        id: 202238,
        name: '3-8：月面着陸～１回転さかおとし',
        score: 3,
        year: 2022,
      },
      { id: 202239, name: '3-9：ターンテーブル～けん', score: 3, year: 2022 },
      { id: 202240, name: '3-10：レジェンド', score: 3, year: 2022 },
      { id: 202241, name: '4-1：スイーツスペシャル', score: 4, year: 2022 },
      {
        id: 202242,
        name: '4-2：飛行機〜1.5回転スワップけん',
        score: 4,
        year: 2022,
      },
      { id: 202243, name: '4-3：ＵＳＡ一周', score: 4, year: 2022 },
      {
        id: 202244,
        name: '4-4：風車けん～風車地球まわし',
        score: 4,
        year: 2022,
      },
      { id: 202245, name: '4-5：ジャグルとめけん', score: 4, year: 2022 },
      { id: 202246, name: '4-6：２回転灯台～けん', score: 4, year: 2022 },
      {
        id: 202247,
        name: '4-7：たけうま～１回転さかおとし',
        score: 4,
        year: 2022,
      },
      {
        id: 202248,
        name: '4-8：うら月面～月面～１回転さかおとし',
        score: 4,
        year: 2022,
      },
      { id: 202249, name: '4-9：Ｃウィップ～飛行機', score: 4, year: 2022 },
      {
        id: 202250,
        name: '4-10：うぐいすの谷渡り～ダウンスパイク',
        score: 4,
        year: 2022,
      },
      { id: 202251, name: '5-1：タップジャグルけん', score: 5, year: 2022 },
      {
        id: 202252,
        name: '5-2：２回転飛行機〜２回転はねけん',
        score: 5,
        year: 2022,
      },
      { id: 202253, name: '5-3：デンマーク一周', score: 5, year: 2022 },
      { id: 202254, name: '5-4：ダブルワールウィンド', score: 5, year: 2022 },
      {
        id: 202255,
        name: '5-5：うぐいすの谷渡り〜中皿極意〜回転けん',
        score: 5,
        year: 2022,
      },
      {
        id: 202256,
        name: '5-6：ゴースト灯台インスタとんぼ返り～けん',
        score: 5,
        year: 2022,
      },
      {
        id: 202257,
        name: '5-7：たけうま〜たけうまとんぼ返り〜ダウンスパイク',
        score: 5,
        year: 2022,
      },
      {
        id: 202258,
        name: '5-8：ボーダーバランス～１回転さかおとし',
        score: 5,
        year: 2022,
      },
      {
        id: 202259,
        name: '5-9：ろうそく～ジャグルろうそく～けん',
        score: 5,
        year: 2022,
      },
      {
        id: 202260,
        name: '5-10：稲妻おとし１回転灯台～ダウンスパイク',
        score: 5,
        year: 2022,
      },
      {
        id: 202261,
        name: '6-1：稲妻おとしスワップペンギンけん',
        score: 6,
        year: 2022,
      },
      {
        id: 202262,
        name: '6-2：１回転灯台インスタスワップ1.5回転トスイン',
        score: 6,
        year: 2022,
      },
      { id: 202263, name: '6-3：さかおとし一周', score: 6, year: 2022 },
      {
        id: 202264,
        name: '6-4：うらけんフリップ灯台～１回転すくいけんファストハンド',
        score: 6,
        year: 2022,
      },
      { id: 202265, name: '6-5：２タップスワップけん', score: 6, year: 2022 },
      {
        id: 202266,
        name: '6-6：１回転アームバウンスタップ灯台～１回転けん',
        score: 6,
        year: 2022,
      },
      {
        id: 202267,
        name: '6-7：アンダーバード～タップ1.5回転イン',
        score: 6,
        year: 2022,
      },
      {
        id: 202268,
        name: '6-8：金魚すくいスワップ月面～１回転さかおとし',
        score: 6,
        year: 2022,
      },
      {
        id: 202269,
        name: '6-9：１回転灯台～おとしフロア灯台～おとし玉フロアスパイク',
        score: 6,
        year: 2022,
      },
      {
        id: 202270,
        name: '6-10：1.5回転けんフリップ→けんフリップダウンスパイク',
        score: 6,
        year: 2022,
      },
      {
        id: 202271,
        name: '7-1：円月はやてストリングピンチ１回転スワップけん',
        score: 7,
        year: 2022,
      },
      {
        id: 202272,
        name: '7-2：２回転飛行機～うら1.5回転スワップ1.5回転トスイン',
        score: 7,
        year: 2022,
      },
      {
        id: 202273,
        name: '7-3：月面とんぼ返り1-2-3〜けん',
        score: 7,
        year: 2022,
      },
      { id: 202274, name: '7-4：マテオチャンス', score: 7, year: 2022 },
      {
        id: 202275,
        name: '7-5：クッションタップスワップ風車けん',
        score: 7,
        year: 2022,
      },
      {
        id: 202276,
        name:
          '7-6：けん先タップうら月面～けん先タップうら月面～けん先タップイン',
        score: 7,
        year: 2022,
      },
      {
        id: 202277,
        name: '7-7：中皿極意～けんフリップ中皿極意～さかおとし',
        score: 7,
        year: 2022,
      },
      {
        id: 202278,
        name: '7-8：ピボットループ１回転月面〜１回転スワップけん',
        score: 7,
        year: 2022,
      },
      {
        id: 202279,
        name: '7-9：両手スイベルスリンガーけん～両手スイベル地球まわし',
        score: 7,
        year: 2022,
      },
      {
        id: 202280,
        name:
          '7-10：ダウンスパイクファストハンド〜1.5回転スワップジャグルダウンスパイクファストハンド',
        score: 7,
        year: 2022,
      },
      {
        id: 202281,
        name: '8-1：ジャグルスイッチジャグル→ジャグルスイッチジャグルけん',
        score: 8,
        year: 2022,
      },
      {
        id: 202282,
        name: '8-2：クッションタップゴーストジャグルゴーストタップフリップイン',
        score: 8,
        year: 2022,
      },
      {
        id: 202283,
        name: '8-3：ワールウィンド１－２－３',
        score: 8,
        year: 2022,
      },
      {
        id: 202284,
        name: '8-4：けんフリップジャグルうらトスタップジャグルけんフリップけん',
        score: 8,
        year: 2022,
      },
      {
        id: 202285,
        name: '8-5：２タップ灯台インスタうら灯台とんぼ返りインスタジャグルけん',
        score: 8,
        year: 2022,
      },
      {
        id: 202286,
        name: '8-6：たけうま～２タップ１回転たけうま～１回転さかおとし',
        score: 8,
        year: 2022,
      },
      {
        id: 202287,
        name:
          '8-7：うぐいす～リフティングうぐいす×２（両足使用）～ダウンスパイク',
        score: 8,
        year: 2022,
      },
      {
        id: 202288,
        name:
          '8-8：うら月面～フリップスワップけんフリップうらトスうら月面～さかおとし',
        score: 8,
        year: 2022,
      },
      {
        id: 202289,
        name:
          '8-9：ストリングループゴーストうら月面～さかおとし（玉グリップスタート）',
        score: 8,
        year: 2022,
      },
      {
        id: 202290,
        name: '8-10：ろうそくすべりどめ極意～小田原提灯～ダウンスパイク',
        score: 8,
        year: 2022,
      },
      { id: 202291, name: '9-1：ジャグル10回以上→けん', score: 9, year: 2022 },
      {
        id: 202292,
        name: '9-2：飛行機～1.5回転スワップ３回転けんフリップトスタップイン',
        score: 9,
        year: 2022,
      },
      {
        id: 202293,
        name:
          '9-3：うら月面～フリップクッションタップうら月面１-２-３～さかおとし',
        score: 9,
        year: 2022,
      },
      {
        id: 202294,
        name:
          '9-4：けんフリップフリップ1.5回転トスイン～1.5回転スワップけんフリップフリップけん',
        score: 9,
        year: 2022,
      },
      {
        id: 202295,
        name:
          '9-5：２タップ２回転けんフリップジャグル２タップ２回転けんフリップジャグルけん',
        score: 9,
        year: 2022,
      },
      {
        id: 202296,
        name:
          '9-6：うら１回転灯台～インスタうら灯台とんぼ返り×３回→インスタすくいけんペンギンファストハンド',
        score: 9,
        year: 2022,
      },
      {
        id: 202297,
        name:
          '9-7：すくいけんファストハンド～トス灯台インスタ３回転フリップジャグルすくいけんファストハンド',
        score: 9,
        year: 2022,
      },
      {
        id: 202298,
        name:
          '9-8：ジャグルボーダーバランス～ボーダーバランスとんぼ返り～１回転さかおとし',
        score: 9,
        year: 2022,
      },
      {
        id: 202299,
        name:
          '9-9：うらふりうぐいす～スライド大皿極意～回転極意渡り～スライド小皿うぐいす～うら回転けん',
        score: 9,
        year: 2022,
      },
      {
        id: 2022100,
        name:
          '9-10：３回転スワップトス灯台インスタうら３回転フリップスワップトス灯台インスタダウンスパイク',
        score: 9,
        year: 2022,
      },
      {
        id: 2022101,
        name: '10-1：６タップトス灯台インスタダウンスパイク',
        score: 10,
        year: 2022,
      },
      {
        id: 2022102,
        name: '10-2：飛行機～4タップスワップけんフリップトスタップ１回転けん',
        score: 10,
        year: 2022,
      },
      {
        id: 2022103,
        name: '10-3：中皿極意地球まわし1-2-3〜回転けん',
        score: 10,
        year: 2022,
      },
      {
        id: 2022104,
        name: '10-4：うぐいす～３けんフリップジャグル×３→うぐいす～回転けん',
        score: 10,
        year: 2022,
      },
      {
        id: 2022105,
        name:
          '10-5：４回転灯台とんぼ返り～うら４回転灯台とんぼ返り～さかおとし',
        score: 10,
        year: 2022,
      },
      {
        id: 2022106,
        name:
          '10-6：３回転クッションタップ灯台～３回転クッションタップ灯台～３回転フリップけん',
        score: 10,
        year: 2022,
      },
      {
        id: 2022107,
        name: '10-7：２回転ターンテーブルポーズ～２回転うら月面～さかおとし',
        score: 10,
        year: 2022,
      },
      {
        id: 2022108,
        name:
          '10-8：２回転たけうま〜スワップグラビティすくいうぐいす〜2.5回転スワップイン',
        score: 10,
        year: 2022,
      },
      {
        id: 2022109,
        name: '10-9：うら月面～秘竜のぼりけん～さかおとし',
        score: 10,
        year: 2022,
      },
      {
        id: 2022110,
        name:
          '10-10：うぐいす～クラウドバウンスジャグルうぐいす～ダウンスパイク',
        score: 10,
        year: 2022,
      },
      // 2023年
      { id: 202311, name: '1-1：スパイク', score: 1, year: 2023 },
      { id: 202312, name: '1-2：飛行機', score: 1, year: 2023 },
      { id: 202313, name: '1-3：手のせ大皿～けん', score: 1, year: 2023 },
      { id: 202314, name: '1-4：空中ブランコ', score: 1, year: 2023 },
      { id: 202315, name: '1-5：たこやき', score: 1, year: 2023 },
      { id: 202316, name: '1-6：野球', score: 1, year: 2023 },
      { id: 202317, name: '1-7：ろうそく', score: 1, year: 2023 },
      { id: 202318, name: '1-8：大皿', score: 1, year: 2023 },
      { id: 202319, name: '1-9：手拍子つるし持ち', score: 1, year: 2023 },
      { id: 202320, name: '1-10：もしかめ11回', score: 1, year: 2023 },
      { id: 202321, name: '2-1：もしかめ10回以上～けん', score: 2, year: 2023 },
      { id: 202322, name: '2-2：はねけん', score: 2, year: 2023 },
      { id: 202323, name: '2-3：世界一周', score: 2, year: 2023 },
      { id: 202324, name: '2-4：地球まわし', score: 2, year: 2023 },
      { id: 202325, name: '2-5：つるしとめけん', score: 2, year: 2023 },
      { id: 202326, name: '2-6：灯台～さかおとし', score: 2, year: 2023 },
      { id: 202327, name: '2-7：うぐいす～回転けん', score: 2, year: 2023 },
      { id: 202328, name: '2-8：すくいけん', score: 2, year: 2023 },
      { id: 202329, name: '2-9：手のせけん～たけとんぼ', score: 2, year: 2023 },
      { id: 202330, name: '2-10：ダウンスパイク', score: 2, year: 2023 },
      { id: 202331, name: '3-1：宇宙遊泳', score: 3, year: 2023 },
      { id: 202332, name: '3-2：1回転飛行機', score: 3, year: 2023 },
      { id: 202333, name: '3-3：ヨーロッパ一周', score: 3, year: 2023 },
      { id: 202334, name: '3-4：ワールウィンド', score: 3, year: 2023 },
      { id: 202335, name: '3-5：ジャグル大皿～けん', score: 3, year: 2023 },
      { id: 202336, name: '3-6：1回転灯台〜けん', score: 3, year: 2023 },
      {
        id: 202337,
        name: '3-7：大皿～けんフリップ大皿～けん',
        score: 3,
        year: 2023,
      },
      {
        id: 202338,
        name: '3-8：月面着陸～1回転さかおとし',
        score: 3,
        year: 2023,
      },
      { id: 202339, name: '3-9：ターンテーブル～けん', score: 3, year: 2023 },
      { id: 202340, name: '3-10：レジェンド ', score: 3, year: 2023 },
      { id: 202341, name: '4-1：中皿極意～回転けん', score: 4, year: 2023 },
      {
        id: 202342,
        name: '4-2：飛行機～1.５回転スワップけん',
        score: 4,
        year: 2023,
      },
      { id: 202343, name: '4-3：USA一周', score: 4, year: 2023 },
      {
        id: 202344,
        name: '4-4：風車けん～風車地球まわし',
        score: 4,
        year: 2023,
      },
      { id: 202345, name: '4-5：ジャグルとめけん', score: 4, year: 2023 },
      { id: 202346, name: '4-6：2回転灯台～けん', score: 4, year: 2023 },
      {
        id: 202347,
        name: '4-7：たけうま～1回転さかおとし',
        score: 4,
        year: 2023,
      },
      {
        id: 202348,
        name: '4-8：1回転月面～1回転さかおとし',
        score: 4,
        year: 2023,
      },
      {
        id: 202349,
        name: '4-9：すくいけんファストレッグキャッチ',
        score: 4,
        year: 2023,
      },
      { id: 202350, name: '4-10：スイーツスペシャル', score: 4, year: 2023 },
      { id: 202351, name: '5-1：タップジャグルけん', score: 5, year: 2023 },
      {
        id: 202352,
        name: '5-2：2回転飛行機～2回転はねけん',
        score: 5,
        year: 2023,
      },
      { id: 202353, name: '5-3：デンマーク一周', score: 5, year: 2023 },
      { id: 202354, name: '5-4：ダブルワールウィンド', score: 5, year: 2023 },
      {
        id: 202355,
        name: '5-5：飛行機～1.５回転スワップジャグルけん',
        score: 5,
        year: 2023,
      },
      {
        id: 202356,
        name: '5-6：1回転灯台インスタすくいけんファストハンド',
        score: 5,
        year: 2023,
      },
      {
        id: 202357,
        name: '5-7：たけうまとんぼ返り～1回転さかおとし',
        score: 5,
        year: 2023,
      },
      {
        id: 202358,
        name: '5-8：バックワードうら月面～月面～1回転さかおとし',
        score: 5,
        year: 2023,
      },
      { id: 202359, name: '5-9：手拍子日本一周', score: 5, year: 2023 },
      {
        id: 202360,
        name: '5-10：けんフリップフリップけん ',
        score: 5,
        year: 2023,
      },
      {
        id: 202361,
        name: '6-1：稲妻おとし2回転スワップけん',
        score: 6,
        year: 2023,
      },
      {
        id: 202362,
        name: '6-2：1回転灯台インスタスワップ1.５回転トスイン',
        score: 6,
        year: 2023,
      },
      { id: 202363, name: '6-3：ワールウィンド一周', score: 6, year: 2023 },
      {
        id: 202364,
        name: '6-4：スイッチキャッチワールウィンド×2',
        score: 6,
        year: 2023,
      },
      {
        id: 202365,
        name: '6-5：うぐいす～灯台～小皿うぐいす～回転けん',
        score: 6,
        year: 2023,
      },
      {
        id: 202366,
        name: '6-6：2回転灯台～2回転とんぼ返り～2回転さかおとし',
        score: 6,
        year: 2023,
      },
      {
        id: 202367,
        name: '6-7：中皿極意～タップ1.５回転イン',
        score: 6,
        year: 2023,
      },
      { id: 202368, name: '6-8：ユニコーン～さかおとし', score: 6, year: 2023 },
      {
        id: 202369,
        name:
          '6-9：すくいけんファスト股下キャッチ～地球まわし（両足を地面につけておく）',
        score: 6,
        year: 2023,
      },
      {
        id: 202370,
        name: '6-10：火おこしダウンスパイク(両手スタート)',
        score: 6,
        year: 2023,
      },
      {
        id: 202371,
        name: '7-1：稲妻おとしスワップうぐいす～ダウンスパイク',
        score: 7,
        year: 2023,
      },
      {
        id: 202372,
        name: '7-2：飛行機～1.５回転スワップジャグルうぐいす～回転けん',
        score: 7,
        year: 2023,
      },
      { id: 202373, name: '7-3：ジャグル一周', score: 7, year: 2023 },
      { id: 202374, name: '7-4：マテオチャンス', score: 7, year: 2023 },
      {
        id: 202375,
        name: '7-5：プルアップタップスワップゴーストトスタップ1.５回転イン',
        score: 7,
        year: 2023,
      },
      {
        id: 202376,
        name: '7-6：大皿極意地球まわし～回転けん',
        score: 7,
        year: 2023,
      },
      {
        id: 202377,
        name: '7-7：タイトハウス～1回転さかおとし',
        score: 7,
        year: 2023,
      },
      {
        id: 202378,
        name: '7-8：うら月面～ウォングタップ月面～クッションタップイン',
        score: 7,
        year: 2023,
      },
      {
        id: 202379,
        name: '7-9：中皿金魚すくい～ペンギンさかおとし',
        score: 7,
        year: 2023,
      },
      {
        id: 202380,
        name: '7-10：円月はやてトス灯台インスタダウンスパイク ',
        score: 7,
        year: 2023,
      },
      {
        id: 202381,
        name:
          '8-1：けんフリップうらけんフリップジャグルけんフリップうらけんフリップけん',
        score: 8,
        year: 2023,
      },
      { id: 202382, name: '8-2：神風～3回転はねけん', score: 8, year: 2023 },
      { id: 202383, name: '8-3：ワールウィンド1－2－3', score: 8, year: 2023 },
      {
        id: 202384,
        name: '8-4：2タップジャグルけんフリップフリップ2.５回転トスイン',
        score: 8,
        year: 2023,
      },
      {
        id: 202385,
        name:
          '8-5：うら2回転トス灯台インスタ2回転フリップスワップうら2.５回転トスイン',
        score: 8,
        year: 2023,
      },
      {
        id: 202386,
        name: '8-6：灯台～うら1回転クラウドバウンスうら1回転灯台～さかおとし',
        score: 8,
        year: 2023,
      },
      {
        id: 202387,
        name:
          '8-7：たけうまバックフリップ～たけうま渡り～たけうまバックフリップ～1.５回転スワップけん',
        score: 8,
        year: 2023,
      },
      {
        id: 202388,
        name:
          '8-8：前ふりバックワードうら月面～2タップバックワードうら月面～1回転クッションタップイン',
        score: 8,
        year: 2023,
      },
      {
        id: 202389,
        name:
          '8-9：うら月面～うらダーディーフィンガーフリップジャグル×2→うらダーティーフィンガーフリップうら月面～さかおとし',
        score: 8,
        year: 2023,
      },
      {
        id: 202390,
        name: '8-10：円月はやてストリングピンチ1回転灯台インスタダウンスパイク',
        score: 8,
        year: 2023,
      },
      { id: 202391, name: '9-1：ジャグル10回以上→けん', score: 9, year: 2023 },
      {
        id: 202392,
        name: '9-2：2回転飛行機～ピルエット2回転はねけん',
        score: 9,
        year: 2023,
      },
      {
        id: 202393,
        name: '9-3：タイトハウス～ピボットループ１回転灯台～さかおとし',
        score: 9,
        year: 2023,
      },
      {
        id: 202394,
        name: '9-4：風車ジャグル風車ジャグル風車けん',
        score: 9,
        year: 2023,
      },
      {
        id: 202395,
        name: '9-5：けんフリップフリップうぐいす～けんフリップフリップけん',
        score: 9,
        year: 2023,
      },
      {
        id: 202396,
        name:
          '9-6：ピボットループ1回転灯台インスタピボットループ灯台とんぼ返りインスタタップ1回転さかおとし',
        score: 9,
        year: 2023,
      },
      {
        id: 202397,
        name:
          '9-7：うらたけうま～フリップスワップうらトスうらたけうま～うら1回転さかおとし',
        score: 9,
        year: 2023,
      },
      {
        id: 202398,
        name: '9-8：うら月面～うら2回転ジャグル×2→うら2回転トスうら月面～けん',
        score: 9,
        year: 2023,
      },
      {
        id: 202399,
        name:
          '9-9：アンダーバード～バレルロールスワップ月面～バレルロール～バレルロールスワップアンダーバード～ダウンスパイク',
        score: 9,
        year: 2023,
      },
      {
        id: 2023100,
        name: '9-10：大皿極意～けんフリップ大皿極意～回転けん',
        score: 9,
        year: 2023,
      },
      {
        id: 2023101,
        name: '10-1：ふりけん～クラウドバウンスけん',
        score: 10,
        year: 2023,
      },
      {
        id: 2023102,
        name:
          '10-2：すくいけんファストハンド～３回転ジャグルトスタップ３回転ジャグルゴーストトスすくいけんファストハンド',
        score: 10,
        year: 2023,
      },
      {
        id: 2023103,
        name: '10-3：うらトスタップジャグル×３→けん',
        score: 10,
        year: 2023,
      },
      {
        id: 2023104,
        name: '10-4：２タップスワップけんフリップ×３→けん',
        score: 10,
        year: 2023,
      },
      {
        id: 2023105,
        name: '10-5：飛行機～タップスワップ３回転風車トスクッションタップイン',
        score: 10,
        year: 2023,
      },
      {
        id: 2023106,
        name:
          '10-6：けんフリップフリップジャグル２回転けんフリップうら２回転トス灯台インスタスワップうら２回転けんフリップジャグルけん',
        score: 10,
        year: 2023,
      },
      {
        id: 2023107,
        name: '10-7：スイベル中皿極意～３６０スイベル中皿極意～回転けん',
        score: 10,
        year: 2023,
      },
      {
        id: 2023108,
        name:
          '10-8：うら月面～タップ１回転シーリングタップ→タップ１回転うら月面～１回転さかおとし',
        score: 10,
        year: 2023,
      },
      {
        id: 2023109,
        name: '10-9：中皿～ホップ大皿インサイドフィンガーバランス～ホップけん',
        score: 10,
        year: 2023,
      },
      {
        id: 2023110,
        name:
          '10-10：３回転たけうまとんぼ返り～谷渡り～３回転たけうまとんぼ返り～ダウンスパイク',
        score: 10,
        year: 2023,
      },
    ],
  },
  mutations: {
    updateLeftTime: function (state, time) {
      state.leftTime = time
    },
    updatePassedTime: function (state, time) {
      state.passedTime = time
    },
    setGroup: function (state, group) {
      state.group = group
    },
    setItemsA: function (state, itemsA) {
      state.itemsA = itemsA
    },
    setItemsB: function (state, itemsB) {
      state.itemsB = itemsB
    },
    setItemNumA: function (state, num) {
      state.itemNumA = num
    },
    setItemNumB: function (state, num) {
      state.itemNumB = num
    },
    timerOn: function (state, isOn) {
      state.timerOn = isOn
    },
    timerPaused: function (state, isPaused) {
      state.timerPaused = isPaused
    },
    isFinishedA: function (state, isFinished) {
      state.isFinishedA = isFinished
    },
    isFinishedB: function (state, isFinished) {
      state.isFinishedB = isFinished
    },
    setModalDateTime: function (state, dateTime) {
      state.modalDateTime = dateTime
    },
    setModalScoreA: function (state, score) {
      state.modalScoreA = score
    },
    setModalScoreB: function (state, score) {
      state.modalScoreB = score
    },
    setModalTricksA: function (state, tricks) {
      state.modalTricksA = tricks
    },
    setModalTricksB: function (state, tricks) {
      state.modalTricksB = tricks
    },
    isSaveRecordEnabled: function (state, isSaveRecordEnabled) {
      state.isSaveRecordEnabled = isSaveRecordEnabled
    },
    isShowResultEnabled: function (state, isShowResultEnabled) {
      state.isShowResultEnabled = isShowResultEnabled
    },
    setVolume: function (state, volume) {
      state.volume = volume
    },
    setYear: function (state, year) {
      state.year = year
    },
  },
  actions: {},
  modules: {},
})
