
























































import { Component, Vue } from 'vue-property-decorator'

@Component
export default class Settings extends Vue {
  isSaveRecordEnabled = true
  isShowResultEnabled = true
  volume = 100
  lastVolume = 100
  year = 2021

  onChangeYear(): void {
    this.$store.commit('setYear', this.year)
    localStorage.setItem('year', this.year.toString())
    location.reload()
  }
  onChangeVolume(): void {
    this.$store.commit('setVolume', this.volume)
    localStorage.setItem('volume', this.volume.toString())
  }
  onChangeSaveRecord(): void {
    this.$store.commit('isSaveRecordEnabled', this.isSaveRecordEnabled)
    localStorage.setItem(
      'isSaveRecordEnabled',
      this.isSaveRecordEnabled.toString()
    )
  }
  onChangeShowResult(): void {
    this.$store.commit('isShowResultEnabled', this.isShowResultEnabled)
    localStorage.setItem(
      'isShowResultEnabled',
      this.isShowResultEnabled.toString()
    )
  }
  mute(): void {
    if (this.volume > 0) {
      this.lastVolume = this.volume
      this.volume = 0
    } else {
      this.volume = this.lastVolume
    }
    this.onChangeVolume()
  }

  mounted(): void {
    this.isSaveRecordEnabled = this.$store.state.isSaveRecordEnabled
    this.isShowResultEnabled = this.$store.state.isShowResultEnabled
    this.volume = this.$store.state.volume
    this.year = this.$store.state.year
  }
}
